export default {
  path: "/report",
  name: "Report",
  redirect: "/report/store",
  meta: {
    title: "数据报表",
    keepAlive: true,
  },
  component: () => import("../views/report/index.vue"),
  children: [
    {
      path: "store",
      name: "Store",
      redirect: "/report/store/PerformanceSummary",
      meta: {
        title: "门店数据",
        keepAlive: true,
      },
      component: () => import("../views/report/store/index.vue"),
      children: [
        {
          path: "PerformanceSummary",
          name: "PerformanceSummary",
          meta: {
            title: "门店业绩总览",
            keepAlive: true,
          },
          component: () => import("../views/report/store/summary/summary.vue"),
        },
        {
          path: "revenue",
          name: "Revenue",
          meta: {
            title: "营收报表",
            keepAlive: true,
          },
          component: () => import("../views/report/store/revenue/revenue.vue"),
        },
        {
          path: "performance",
          name: "Performance",
          meta: {
            title: "业绩类别统计表",
            keepAlive: true,
          },
          component: () =>
            import("../views/report/store/performance/performance.vue"),
        },
        {
          path: "finance",
          name: "Finance",
          meta: {
            title: "跨店消费统计表",
            keepAlive: true,
          },
          component: () => import("../views/report/store/finance/finance.vue"),
        },
        {
          path: "each",
          name: "each",
          meta: {
            title: "各门店业绩汇总",
            keepAlive: true,
          },
          component: () => import("../views/report/store/each/each.vue"),
        },
        {
          path: "business",
          name: "Business",
          meta: {
            title: "门店营业数据",
            keepAlive: true,
          },
          component: () => import("../views/report/store/business/list.vue"),
        },
        {
          path: "detail",
          name: "Detail",
          meta: {
            title: "门店营业明细",
            keepAlive: true,
          },
          redirect: "/report/store/detail/labor",
          component: () => import("../views/report/store/detail/list.vue"),
          children: [
            {
              path: "labor",
              name: "Labor",
              meta: {
                title: "开单",
                keepAlive: true,
              },
              component: () =>
                import("../views/report/store/detail/labor/list.vue"),
            },
            {
              path: "sales",
              name: "Sales",
              meta: {
                title: "商品销售",
                keepAlive: true,
              },
              component: () =>
                import("../views/report/store/detail/sales/list.vue"),
            },
            {
              path: "openCard",
              name: "OpenCard",
              meta: {
                title: "开充卡",
                keepAlive: true,
              },
              component: () =>
                import("../views/report/store/detail/open/list.vue"),
            },
            {
              path: "treatmentCard",
              name: "TreatmentCard",
              meta: {
                title: "疗程卡",
                keepAlive: true,
              },
              component: () =>
                import("../views/report/store/detail/treatment/list.vue"),
            },
          ],
        },
        {
          path: "class-detail-statis",
          name: "classDetailStatis",
          meta: {
            title: "项目分类统计",
            keepAlive: true,
          },
          component: () =>
            import(
              "../views/report/store/class-detail-statis/class-detail-statis.vue"
            ),
        },
        {
          path: "class-payment-statis",
          name: "classPaymentStatis",
          meta: {
            title: "项目分类付款统计",
            keepAlive: true,
          },
          component: () =>
            import(
              "../views/report/store/class-payment-statis/class-payment-statis.vue"
            ),
        },
      ],
    },
    {
      path: "reportBusiness",
      name: "ReportBusiness",
      redirect: "/report/reportBusiness/itemsConsumption",
      meta: {
        title: "营业数据",
        keepAlive: true,
      },
      component: () => import("../views/report/business/index.vue"),
      children: [
        {
          path: "itemsConsumption",
          name: "ItemsConsumption",
          meta: {
            title: "项目消费表",
            keepAlive: true,
          },
          component: () => import("../views/report/business/items-consumption/items-consumption.vue"),
        },
        {
          path: "openCharge",
          name: "OpenCharge",
          meta: {
            title: "开充卡",
            keepAlive: true,
          },
          component: () => import("../views/report/business/open-charge/open-charge.vue"),
        }
      ],
    },
    {
      path: "employee",
      name: "reportEmployee",
      redirect: "/report/employee/total",
      meta: {
        title: "员工数据",
        keepAlive: true,
      },
      component: () => import("../views/report/employee/index.vue"),
      children: [
        {
          path: "total",
          name: "Total",
          meta: {
            title: "员工业绩汇总表",
            keepAlive: true,
          },
          component: () => import("../views/report/employee/total/total.vue"),
        },
        {
          path: "roya",
          name: "roya",
          meta: {
            title: "员工提成汇总表",
            keepAlive: true,
          },
          component: () => import("../views/report/employee/roya/roya.vue"),
        },
        {
          path: "ranking",
          name: "ranking",
          meta: {
            title: "各门店员工业绩汇总排行",
            keepAlive: true,
          },
          component: () =>
            import("../views/report/employee/ranking/ranking.vue"),
        },
        {
          path: "detail",
          name: "employeeDetail",
          redirect: "/report/employee/detail/labor",
          meta: {
            title: "员工销售明细",
            keepAlive: true,
          },
          component: () => import("../views/report/employee/detail/index.vue"),
          children: [
            {
              path: "labor",
              name: "employeeLabor",
              meta: {
                title: "劳动",
                keepAlive: true,
              },
              component: () =>
                import("../views/report/employee/detail/labor/list.vue"),
            },
            {
              path: "sales",
              name: "employeeSales",
              meta: {
                title: "商品",
                keepAlive: true,
              },
              component: () =>
                import("../views/report/employee/detail/sales/list.vue"),
            },
            {
              path: "openCard",
              name: "employeeOpenCard",
              meta: {
                title: "开充卡",
                keepAlive: true,
              },
              component: () =>
                import("../views/report/employee/detail/open/list.vue"),
            },
            {
              path: "treatmentCard",
              name: "employeeTreatmentCard",
              meta: {
                title: "疗程卡",
                keepAlive: true,
              },
              component: () =>
                import("../views/report/employee/detail/treatment/list.vue"),
            },
          ],
        },
        {
          path: "results",
          name: "Results",
          meta: {
            title: "员工业绩提成",
            keepAlive: true,
          },
          component: () => import("../views/report/employee/results/list.vue"),
        },
        {
          path: "summary",
          name: "Summary",
          meta: {
            title: "员工业绩汇总表",
            keepAlive: true,
          },
          component: () => import("../views/report/employee/summary/list.vue"),
        },
        {
          path: "class-statis",
          name: "ClassStatis",
          meta: {
            title: "员工项目分类汇总表",
            keepAlive: true,
          },
          component: () =>
            import("../views/report/employee/class-statis/class-statis.vue"),
        },
        {
          path: "perfor-analyse",
          name: "PerforAnalyse",
          meta: {
            title: "员工业绩分析表",
            keepAlive: true,
          },
          component: () =>
            import(
              "../views/report/employee/perfor-analyse/perfor-analyse.vue"
            ),
        },
        {
          path: "class-trend",
          name: "ClassTrend",
          meta: {
            title: "员工项目分类趋势分析",
            keepAlive: true,
          },
          component: () =>
            import("../views/report/employee/class-trend/class-trend.vue"),
        },
        {
          path: "monthly",
          name: "Monthly",
          meta: {
            title: "员工分类业绩月度统计",
            keepAlive: true,
          },
          component: () =>
            import("../views/report/employee/monthly/monthly.vue"),
        },
      ],
    },
    {
      path: "salary",
      name: "Salary",
      redirect: "/report/salary/quailfy",
      meta: {
        title: "员工薪资",
        keepAlive: true,
      },
      component: () => import("../views/report/salary/index.vue"),
      children: [
        {
          path: "quailfy",
          name: "Quailfy",
          meta: {
            title: "达标提成",
            keepAlive: true,
          },
          component: () => import("../views/report/salary/quailfy/quailfy.vue"),
        },
      ],
    },
    {
      path: "financial",
      name: "Financial",
      redirect: "/report/financial/profit-by-cash",
      meta: {
        title: "财务数据",
        keepAlive: true,
      },
      component: () => import("../views/report/financial/index.vue"),
      children: [
        {
          path: "income",
          name: "Income",
          meta: {
            title: "收入统计",
            keepAlive: true,
          },
          component: () => import("../views/report/financial/income/list.vue"),
        },
        {
          path: "profit-by-cash",
          name: "profit-by-cash",
          meta: {
            title: "损益表按现金业绩",
            keepAlive: true,
          },
          component: () =>
            import(
              "../views/report/financial/profit-by-cash/profit-by-cash.vue"
            ),
        },
      ],
    },
    {
      path: "member",
      name: "reportMember",
      redirect: "/report/member/expend-analysis",
      meta: {
        title: "会员数据",
        keepAlive: true,
      },
      component: () => import("../views/report/member/index.vue"),
      children: [
        {
          path: "expend-analysis",
          name: "ExpendAnalysis",
          meta: {
            title: "顾客消费分析",
            keepAlive: true,
          },
          component: () =>
            import(
              "../views/report/member/expend-analysis/expend-analysis.vue"
            ),
        },
      ],
    },
  ],
};
