import {
  request
} from "../utils/axios.js";
export default {
  // 会员 ==> 会员卡分类设置
  getCardkindlist(params = {}) {
    return request('get', `/card/get_cardkindlist`, params);
  },

  addCardkind(params = {}) {
    return request('post', `/card/add_cardkind`, params);
  },

  updateCardkind(params = {}) {
    return request('post', `/card/update_cardkind`, params);
  },

  deleteCardkind(params = {}) {
    return request('get', `/card/delete_cardkind`, params);
  },

  // 会员 ==> 会员卡类型管理
  getCardMainList(params = {}) {
    return request('get', `/card/get_card_main_list`, params);
  },

  deleteCard(params = {}) {
    return request('GET', `/card/delete_card`, params)
  },

  restoreCard(params = {}) {
    return request('GET', `/card/restore_card`, params)
  },

  disabledCard(params = {}) {
    return request('GET', `/card/disable_card`, params)
  },

  getCardExtra(params = {}) {
    return request('get', `/card/get_card_extra`, params)
  },

  getCardDonamoneyList(params = {}) {
    return request('get', `/card/get_card_donamoney_list`, params);
  },

  getCardDiscclassList(params = {}) {
    return request('get', `/card/get_card_discclass_list`, params);
  },

  getCardItemclassList(params = {}) {
    return request('get', `/card/get_card_itemclass_list`, params);
  },

  initSaveCard(params = {}) {
    return request('get', `/card/init_save_card`, params);
  },

  saveCard(params = {}) {
    return request('post', `/card/save_card`, params);
  },

  // 会员 ==> 会员卡类型设置
  initCardSettingList(params = {}) {
    return request('get', `/card/init_card_setting`, params)
  },

  getDiscClassList(params = {}) {
    return request('get', `/card/get_disc_class`, params)
  },

  saveDiscClass(params = {}) {
    return request('post', `/card/save_disc_class`, params)
  },

  deleteDiscClass(params = {}) {
    return request('get', `/card/delete_disc_class`, params)
  },

  getDiscItemList(params = {}) {
    return request('get', `/card/get_disc_item`, params)
  },

  saveDiscItem(params = {}) {
    return request('post', `/card/save_disc_item`, params)
  },

  deleteDiscItem(params = {}) {
    return request('get', `/card/delete_disc_item`, params)
  },

  getDonaMoneyList(params = {}) {
    return request('get', `/card/get_dona_money`, params)
  },

  saveDonaMoney(params = {}) {
    return request('post', `/card/save_dona_money`, params)
  },

  deleteDonaMoney(params = {}) {
    return request('get', `/card/delete_dona_moneny`, params)
  },

  getInteClassList(params = {}) {
    return request('get', `/card/get_inte_class`, params)
  },

  saveInteClass(params = {}) {
    return request('post', `/card/save_inte_class`, params)
  },

  deleteInteClass(params = {}) {
    return request('get', `/card/delete_inte_class`, params)
  },

  getInteItemList(params = {}) {
    return request('get', `/card/get_inte_item`, params)
  },

  saveInteItem(params = {}) {
    return request('post', `/card/save_inte_item`, params)
  },

  deleteInteItem(params = {}) {
    return request('get', `/card/delete_inte_item`, params)
  },

  getInteKeepList(params = {}) {
    return request('get', `/card/get_inte_keep`, params)
  },

  saveInteKeep(params = {}) {
    return request('post', `/card/save_inte_keep`, params)
  },

  deleteInteKeep(params = {}) {
    return request('get', `/card/delete_inte_keep`, params)
  },

  getDonaItemList(params = {}) {
    return request('get', `/card/get_dona_item`, params)
  },

  saveDonaItem(params = {}) {
    return request('post', `/card/save_dona_item`, params)
  },

  deleteDonaItem(params = {}) {
    return request('get', `/card/delete_dona_item`, params)
  },

  getPackItemList(params = {}) {
    return request('get', `/card/get_pack_item`, params)
  },

  savePackItem(params = {}) {
    return request('post', `/card/save_pack_item`, params)
  },

  deletePackItem(params = {}) {
    return request('get', `/card/delete_pack_item`, params)
  },

  getAppoItemList(params = {}) {
    return request('get', `/card/get_appo_item`, params)
  },

  saveAppoItem(params = {}) {
    return request('post', `/card/save_appo_item`, params)
  },

  deleteAppoItem(params = {}) {
    return request('get', `/card/delete_appo_item`, params)
  },

  getPerforRoyaList(params = {}) {
    return request('get', `/card/get_perfor_roya`, params)
  },

  savePerforRoya(params = {}) {
    return request('post', `/card/save_perfor_roya`, params)
  },

  deletePerforRoya(params = {}) {
    return request('get', `/card/delete_perfor_roya`, params)
  },

  // 上传封面图片
  uploadCoverPicture(params = {}) {
    return request('post', `/card/upload_cover_picture`, params)
  },

  // 删除封面图片
  deleteCoverPricture(params = {}) {
    return request('get', `/card/delete_cover_pricture`, params)
  },
}