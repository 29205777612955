<template>
  <!-- start 项目选择对话框 -->
  <el-dialog
    title="项目选择"
    :visible.sync="showDialog"
    width="45%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCloseDialog"
  >
    <!-- start 自定义内容 -->
    <div class="content-box">
      <!-- el-aside -->
      <el-aside>
        <el-tree
          :data="treeData"
          node-key="id"
          draggable
          accordion
          :highlight-current="true"
          :default-expanded-keys="defaultExpandedKeys"
          :allow-drag="allowDrag"
          :current-node-key="currentNodeKey"
          @node-click="nodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
      </el-aside>
      <!-- end el-aside -->

      <!-- el-main -->
      <el-main>
        <el-input
          size="small"
          placeholder="输入商品名称或编号进行搜索"
          v-model="word"
          @input="initPageData"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <!-- table -->
        <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="tableData"
          row-key="ItemGuid"
          stripe
          :header-cell-class-name="headerStyle"
          @select="onSelectChange"
          @select-all="onSelectChange"
        >
          <el-table-column type="selection" width="46"></el-table-column>
          <el-table-column prop="ItemName" label="项目名称"></el-table-column>
          <el-table-column prop="Price" label="单价">
            <template slot-scope="scope">
              <span class="table-red-font">{{ scope.row.Price }}¥</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- end table -->
      </el-main>
      <!-- end el-main -->
    </div>
    <!-- end 自定义内容 -->

    <!-- start 脚部 -->
    <span slot="footer" class="dialog-footer">
      <!-- pagination -->
      <el-pagination
        background
        :page-sizes="[15, 20, 25]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRecord"
        :pager-count="5"
        size="small"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!--pagination-->

      <div>
        <el-button size="small" @click="onCloseDialog">取 消</el-button>
        <el-button size="small" type="primary" @click="onConfirmData"
          >保 存</el-button
        >
      </div>
    </span>
    <!-- end 脚部 -->
  </el-dialog>
  <!-- end 项目选择对话框 -->
</template>

<script>
import service from "@/api/service.js";

export default {
  props: {
    multiple: {
      // 表格是否多选
      type: Boolean,
      default: () => true,
    },
  },

  computed: {
    headerStyle({ row, column, rowIndex, columnIndex }) {
      return this.multiple ? "" : "hideSelectAll";
    },
  },

  data() {
    return {
      pageIndex: 1, // 页码
      pageSize: 15, // 页数
      totalRecord: 0, // 总列数
      currentNodeKey: "ALL", // 选中项目组id
      word: "", // 关键词
      treeData: [], // 树形项目组列表
      tableData: [], // 消费项目表格数据
      selectItems: [], // 多选选中项目数据
      defaultExpandedKeys: ["ALL"], // 树形控件默认勾选节点
      loading: false, // 表格加载
      showDialog: false, // 对话框显示
    };
  },

  methods: {
    // 获取项目组数据
    async initTreeData() {
      try {
        let { data } = await service.getItemshowTree();
        this.treeData = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取消费项目数据
    async initPageData() {
      try {
        let submitData = {
          page_index: this.pageIndex,
          page_size: this.pageSize,
          word: this.word,
          item_state: 1,
          item_type: 1,
          show_guid: this.currentNodeKey,
        };
        let { data, errcode } = await service.getItemMaintItemList(submitData);
        if (errcode == 0) {
          let { filter, itemMaintItemList } = data;
          this.tableData = itemMaintItemList;
          this.totalRecord = filter.TotalRecord;
          this.onRowSelection();
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },

    // 树形控件点击事件
    nodeClick(data, dragNode, enterNode) {
      this.currentNodeKey = data.id;
      this.defaultExpandedKeys = [data.id];
      this.initPageData();
    },

    // 判断节点能否被拖拽, 如果为 false 不允拖动。
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf("全部显示组") === -1;
    },

    // 多选框改变事件
    onSelectChange(event, row) {
      if (!this.multiple) {
        this.selectItems = [row];
        this.$refs.multipleTable.clearSelection()
        return this.onRowSelection()
      }
      this.selectItems = event;
    },

    // 多选回显
    onRowSelection() {
      this.$nextTick(() => {
        this.selectItems.forEach((item) => {
          for (let index = 0; index < this.tableData.length; index++) {
            if (this.tableData[index].ItemGuid === item.ItemGuid) {
              this.$refs.multipleTable.toggleRowSelection(
                this.tableData[index],
                true
              );
              break;
            }
          }
        });
      });
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.initPageData();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.initPageData();
    },

    // 确定按钮点击事件
    onConfirmData() {
      this.$emit("onConfirm", { selectItems: this.selectItems });
      this.onCloseDialog();
    },

    // 打开对话框
    onShowDialog(event) {
      this.selectItems = event ? JSON.parse(JSON.stringify(event)) : [];
      this.initTreeData();
      this.initPageData();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetSelectedData();
      this.showDialog = false;
    },

    // 重置数据
    resetSelectedData() {
      this.pageIndex = 1;
      // this.pageSize = 15
      this.totalRecord = 0;
      this.selectItems = [];
      this.currentNodeKey = "ALL";
      this.defaultExpandedKeys = ["ALL"];
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;
  height: 40vh;
  .flex-row();

  .el-aside {
    width: 160px !important;
    height: 100%;
    overflow-y: auto;
  }

  .el-main {
    .el-table {
      margin-top: 20px;
      height: calc(40vh - 50px);
      overflow: auto;
    }
  }
}
.dialog-footer {
  .flex-row();
  justify-content: space-between;
  align-items: center;
}

::v-deep .hideSelectAll .el-checkbox {
  visibility: hidden;
}

.scrollbar();
</style>
