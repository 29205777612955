import { request } from "../utils/axios"

export default {
	// 商城 ===> 商品分类
	getCategoryList(params = {}) {
		return request('get', `/shop/get_category_list`, params);
	},

	saveCategory(params = {}) {
		return request('post', `/shop/save_category`, params);
	},

    deleteCategory(params = {}) {
		return request('get', `/shop/delete_category`, params);
	},

    // 商城 ===> 商品列表
	initItemMaintList(params = {}) {
		return request('get', `/shop/init_item_maint_list`, params);
	},

    getItemMaintList(params = {}) {
		return request('get', `/shop/get_item_maint_list`, params);
	},

	initItem(params = {}) {
		return request('get', `/shop/init_item`, params);
	},

	addAttrKey(params = {}) {
		return request('get', `/shop/add_attr_key`, params);
	},

	addAttrValue(params = {}) {
		return request('get', `/shop/add_attr_value`, params);
	},

	saveItem(params = {}) {
		return request('post', `/shop/save_item`, params);
	},

	deleteItem(params = {}) {
		return request('get', `/shop/delete_item`, params);
	},

    // 商城 ===> 拼团商品
	getGrouponSettingMaintList(params = {}) {
		return request('get', `/shop/get_groupon_setting_maint_list`, params);
	},

	initGrouponSetting(params = {}) {
		return request('get', `/shop/init_groupon_setting`, params);
	},

	initGrouponPriceList(params = {}) {
		return request('get', `/shop/init_groupon_price_list`, params);
	},

	saveGrouponSetting(params = {}) {
		return request('post', `/shop/save_groupon_setting`, params);
	},

	deleteGrouponPrice(params = {}) {
		return request('get', `/shop/delete_groupon_price`, params);
	},

	deleteGrouponSetting(params = {}) {
		return request('get', `/shop/delete_groupon_setting`, params);
	},

	// 商城 ===> 订单管理
	initOrderMaintList(params = {}) {
		return request('get', `/shop/init_order_maint_list`, params);
	},

	getOrderMaintList(params = {}) {
		return request('get', `/shop/get_order_maint_list`, params);
	},

	getOrderMaintDetail(params = {}) {
		return request('get', `/shop/get_order_maint_detail`, params);
	},

	getVerifierSelector(params = {}) {
		return request('get', `/shop/get_verifier_selector`, params);
	},

	verifyOrder(params = {}) {
		return request('get', `/shop/verify_order`, params);
	},

	auditCanceling(params = {}) {
		return request('get', `/shop/audit_canceling`, params);
	},

	refundCanceling(params = {}) {
		return request('get', `/shop/refund_canceling`, params);
	},

	// 商城 ===> 拼团记录
	getGrouponRecordList(params = {}) {
		return request('get', `/shop/get_groupon_maint_list`, params);
	},

	getGrouponPartnerList(params = {}) {
		return request('get', `/shop/get_groupon_partner_list`, params);
	},

	// 商城 ===> 商城主页设置
	initHomeSetting(params = {}) {
		return request('get', `/shop/init_home_setting`, params);
	},

	saveHomeSetting(params = {}) {
		return request('post', `/shop/save_home_setting`, params);
	},
}