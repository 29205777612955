<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "张三",
    };
  },

  methods: {},
};
</script>

<style lang="less">
@import "./assets/df-icon/iconfont.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-popover {
  min-width: 0 !important;
}

a {
  text-decoration: none;
}

.df-row {
  display: flex;
  margin-bottom: 20px;

  .df-col {
    min-height: 20px;
    margin-right: 10px;
  }
}

.summary_number {
  flex: 1;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;

  .value {
    padding: 0 5px;
    font-weight: bold;
    color: #f83051;
  }
  .text-small {
    cursor: pointer;
    margin-left: 20px;
    color: #0ae;
  }

  .search-input {
    margin-left: 10px;
  }
}

.el-table {
  border-bottom: 1px solid #bbb !important;
  border-right: 1px solid #bbb !important;

  thead {
    color: #363636 !important;
  }

  .el-table__header .has-gutter {
    color: #363636 !important;
  }

  // .el-table--border,
  // .el-table--group,
  // .el-table--border td,
  // .el-table--border th {
  //   border-color: #000 !important;
  // }
  // .el-table__footer .has-gutter td{
  //   border-top: 1px solid #bbb !important;
  // }

  &,
  thead tr th,
  tbody .el-table__row td {
    border-color: #bbb !important;
  }

  .el-table__row--striped td {
    background-color: #f5f5f5 !important;
  }

  .el-table__body tr:hover > td {
    background-color: #d5e4ff !important;
  }

  .table-empl-name {
    color: #0ae;
  }

  .table-yellow-font {
    color: rgb(255, 139, 6);
  }

  .table-green-font {
    color: limegreen;
  }

  .table-red-font {
    color: red;
  }

  tbody {
    .table-grey-bgcolor {
      background-color: #e6e6e6 !important;
    }
  }
}

// vue-quill-editor 行高编辑器样式 在scoped局部样式覆盖不生效
.ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
  content: "行高";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1"]::before {
  content: "1";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.5"]::before {
  content: "1.5";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.75"]::before {
  content: "1.75";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="2"]::before {
  content: "2";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="3"]::before {
  content: "3";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="4"]::before {
  content: "4";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="5"]::before {
  content: "5";
}
.ql-snow .ql-picker.ql-lineheight {
  width: 70px;
}
</style>
