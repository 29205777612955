export default { 
	path: '/shop',
	name: 'Shop',
    redirect: '/shop/goods',
	meta:{
		title: '商城',
	},
	component: ()=> import('../views/shop/index.vue'),
	children:[
		{
			path: 'goods',
			name: 'Goods',
            redirect: '/shop/goods/list',
			meta:{
				title: '商品管理',
			},
			component: ()=> import('../views/shop/goods/index.vue'),
            children:[
                {
                    path: 'list',
                    name: 'GoodsList',
                    meta:{
                        title: '商品列表',
                    },
                    component: ()=> import('../views/shop/goods/list/list.vue'),
                },
                {
                    path: 'category',
                    name: 'GoodsCategory',
                    meta:{
                        title: '商品分类',
                    },
                    component: ()=> import('../views/shop/goods/category/category.vue'),
                }
            ]
		},
        {
			path: 'order',
			name: 'Order',
			meta:{
				title: '订单管理',
			},
			component: ()=> import('../views/shop/order/order.vue'),
		},
        {
			path: 'group',
			name: 'Group',
			redirect: '/shop/group/wares',
			meta:{
				title: '拼团管理',
			},
			component: ()=> import('../views/shop/group/index.vue'),
			children:[
			    {
                    path: 'wares',
                    name: 'Wares',
                    meta:{
                        title: '拼团商品',
                    },
                    component: ()=> import('../views/shop/group/wares/wares.vue'),
			    },
				{
                    path: 'record',
                    name: 'Record',
                    meta:{
                        title: '拼团记录',
                    },
                    component: ()=> import('../views/shop/group/record/record.vue'),
				}
			]
		},
		{
			path: 'setting',
			name: 'Setting',
			redirect: '/shop/setting/home',
			meta:{
				title: '商城设置',
			},
			component: ()=> import('../views/shop/setting/index.vue'),
			children:[
			    {
                    path: 'home',
                    name: 'Home',
                    meta:{
                        title: '主页设置',
                    },
                    component: ()=> import('../views/shop/setting/home/home.vue'),
			    }
			]
		},
	]
}