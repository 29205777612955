<template>
  <!-- start 会员卡选择对话框 -->
  <el-dialog
    title="会员卡选择"
    :visible.sync="showDialog"
    width="55%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCloseDialog"
  >
    <!-- start 自定义内容 -->
    <div class="content-box">
      <!-- el-aside -->
      <el-aside>
        <el-tabs v-model="natureNo" tab-position="left" @tab-click="nodeClick">
          <el-tab-pane
            v-for="(item, index) in cardNatureList"
            :key="item.NatureNo"
            :label="item.NatureName"
            :name="item.NatureNo"
          >
          </el-tab-pane>
        </el-tabs>
      </el-aside>
      <!-- end el-aside -->

      <!-- el-main -->
      <el-main>
        <el-input
          size="small"
          placeholder="输入商品名称或编号进行搜索"
          v-model="word"
          @input="getCardMainList"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <!-- table -->
        <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="cardMainList"
          row-key="CardGuid"
          stripe
          :header-cell-class-name="headerStyle"
          @select="onSelectChange"
          @select-all="onSelectChange"
        >
          <el-table-column type="selection" width="46"></el-table-column>
          <el-table-column label="会员卡">
            <template slot-scope="scope">
              <div class="image-text">
                <!-- <div class="el-image">
                  <img src="@/assets/img/gold.jpg" />
                </div> -->
                <div class="text">
                  <div>
                    <span>{{ scope.row.CardName }}</span>
                  </div>
                  <div class="size">卡号:{{ scope.row.CardNo }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="CostPrice"
            label="售价"
            width=""
          ></el-table-column>
          <el-table-column
            prop="KeepPrice"
            label="开卡储值金额"
            width=""
          ></el-table-column>
          <el-table-column
            prop="KindName"
            label="分类"
            width=""
          ></el-table-column>
          <el-table-column label="有效期限" width="">
            <template slot-scope="scope">
              <div>{{ scope.row.DateLimit || "无限期" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="DateUnitsText"
            label="期限单位"
            width=""
          ></el-table-column>
          <el-table-column label="门店通用" width="">
            <template slot-scope="scope">
              <div>{{ scope.row.GlobalStores | whether }}</div>
            </template>
          </el-table-column>
          <el-table-column label="是属总部" width="">
            <template slot-scope="scope">
              <div>{{ scope.row.HeadMark | whether }}</div>
            </template>
          </el-table-column>
        </el-table>
        <!-- end table -->
      </el-main>
      <!-- end el-main -->
    </div>
    <!-- end 自定义内容 -->

    <!-- start 脚部 -->
    <span slot="footer" class="dialog-footer">
      <!-- start 分页器 -->
      <el-pagination
        background
        :page-sizes="[15, 20, 25]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRecord"
        :pager-count="5"
        size="small"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!-- end 分页器 -->

      <div>
        <el-button size="small" @click="onCloseDialog">取 消</el-button>
        <el-button size="small" type="primary" @click="onConfirmData"
          >保 存</el-button
        >
      </div>
    </span>
    <!-- end 脚部 -->
  </el-dialog>
  <!-- end 会员卡选择对话框 -->
</template>

<script>
import card from "@/api/card.js";

export default {
  props: {
    multiple: {
      // 表格是否多选
      type: Boolean,
      default: () => true,
    },
  },

  computed: {
    // 表头样式
    headerStyle({ row, column, rowIndex, columnIndex }) {
      return this.multiple ? "" : "hideSelectAll";
    },
  },

  filters: {
    // 状态过滤
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  data() {
    return {
      natureNo: "0001", // 当前选中会员卡类型
      pageIndex: 1, // 页码
      pageSize: 15, // 页数
      totalRecord: 0, // 总列数
      word: "", // 关键词
      cardNatureList: [],   // 卡类型列表
      cardMainList: [], // 会员卡数据列表
      selectItems: [], // 多选选中项目数据
      loading: false, // 表格加载
      showDialog: false, // 对话框显示
    };
  },

  methods: {
    // 初始化页面表格数据
    async getCardMainList() {
      this.loading = true;
      try {
        let submitData = {
          page_index: this.pageIndex,
          page_size: this.pageSize,
          card_state: 1,
          natureno: this.natureNo,
          word: this.word,
        };

        let { data } = await card.getCardMainList(submitData);
        let { cardMainList, cardNatureList, filter } = data;
        this.cardMainList = cardMainList;
        this.cardNatureList = cardNatureList;
        this.totalRecord = filter.TotalRecord;
        this.onRowSelection();
        // console.log(this.cardMainList);
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },

    // tag点击事件
    nodeClick(event) {
        this.resetSelectedData()
        this.getCardMainList()
    },

    // 判断节点能否被拖拽, 如果为 false 不允拖动。
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf("全部显示组") === -1;
    },

    // 多选框改变事件
    onSelectChange(event, row) {
      if (!this.multiple) {
        this.selectItems = [row];
        this.$refs.multipleTable.clearSelection();
        return this.onRowSelection();
      }
      this.selectItems = event;
    },

    // 多选回显
    onRowSelection() {
      this.$nextTick(() => {
        this.selectItems.forEach((item) => {
          for (let index = 0; index < this.cardMainList.length; index++) {
            if (this.cardMainList[index].CardGuid === item.CardGuid) {
              this.$refs.multipleTable.toggleRowSelection(
                this.cardMainList[index],
                true
              );
              break;
            }
          }
        });
      });
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.getCardMainList();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.getCardMainList();
    },

    // 确定按钮点击事件
    onConfirmData() {
      this.$emit("onConfirm", { selectItems: this.selectItems });
      this.onCloseDialog();
    },

    // 打开对话框
    onShowDialog(event) {
      this.selectItems = event ? JSON.parse(JSON.stringify(event)) : [];
      this.getCardMainList();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetSelectedData();
      this.showDialog = false;
    },

    // 重置数据
    resetSelectedData() {
      this.pageIndex = 1;
      // this.pageSize = 15
      this.totalRecord = 0;
      this.selectItems = [];
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;
  height: 40vh;
  .flex-row();

  .el-aside {
    width: 80px !important;
    height: 100%;
    overflow-y: auto;

    ::v-deep .el-tabs {
      position: relative;
      height: 100%;

      .el-tabs__header {
        width: 80px;
        .el-tabs__item {
          max-width: 140px;
          padding-left: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
    }
  }

  .el-main {
    .el-table {
      margin-top: 20px;
      height: calc(40vh - 50px);
      overflow: auto;
      .image-text {
        display: flex;
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 56px;
          //   margin-left: 10px;
          width: 200px;
          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .size {
            font-size: 10px;
          }
          i {
            color: #6aabdb;
            font-weight: bold;
            margin-left: 5px;
          }
        }
      }

      .el-image {
        width: 100px;
        height: 56px;
        text-align: center;
        line-height: 56px;
        font-weight: bold;
        font-size: 20px;
        border-radius: 5px;
        vertical-align: middle;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.dialog-footer {
  .flex-row();
  justify-content: space-between;
  align-items: center;
}

::v-deep .hideSelectAll .el-checkbox {
  visibility: hidden;
}

.scrollbar();
</style>
