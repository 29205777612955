import {
	request
} from "../utils/axios";

export default {
	// 服务与商品 ==> 项目设置 ==> 项目分类
	getItembasic(params = {}) {
		return request('get', `/Item/get_itembasic`, params);
	},

	addItembasic(params = {}) {
		return request('post', `/Item/add_itembasic`, params);
	},

	updateItembasic(params = {}) {
		return request('post', `/Item/update_itembasic`, params);
	},

	deleteItembasic(params = {}) {
		return request('get', `/Item/delete_itembasic`, params);
	},

	// 服务与商品 ==> 项目设置 ==> 预约分类
	getItemwechat(params = {}) {
		return request('get', `/Item/get_itemwechat`, params);
	},

	addItemwechat(params = {}) {
		return request('post', `/Item/add_itemwechat`, params);
	},

	updateItemwechat(params = {}) {
		return request('post', `/Item/update_itemwechat`, params);
	},

	deleteItemwechat(params = {}) {
		return request('get', `/Item/delete_itemwechat`, params);
	},

	// 服务与商品 ==> 提成设置 ==> 分类批量设置
	getItemclass(params = {}) {
		return request('get', `/Item/get_itemclass`, params);
	},

	addItemclass(params = {}) {
		return request('post', `/Item/add_itemclass`, params);
	},

	updateItemclass(params = {}) {
		return request('post', `/Item/update_itemclass`, params);
	},

	deleteItemclass(params = {}) {
		return request('get', `/Item/delete_itemclass`, params);
	},

	// 服务与商品 ==> 项目设置 ==> 服务项目
	getItemshowTree(params = {}) {
		return request('get', `/Item/get_itemshow_tree`, params);
	},

	addItemShow(params = {}) {
		return request('post', `/Item/add_itemshow`, params);
	},

	updateItemShow(params = {}) {
		return request('post', `/Item/update_itemshow`, params);
	},

	deleteItemShow(params = {}) {
		return request('get', `/Item/delete_itemshow`, params);
	},

	getItemMaintItemList(params = {}) {
		return request('get', `/item/get_item_maint_list`, params);
	},

	initSaveItemData(params = {}) {
		return request('get', `/item/init_save_item_data`, params);
	},

	saveItem(params = {}) {
		return request('post', `/item/save_item`, params);
	},

	deleteItem(params = {}) {
		return request('post', `/item/delete_item`, params);
	},

	deleteItemPricture(params = {}) {
		return request('get', `/item/delete_item_pricture`, params)
	}
}