import { request } from "../utils/axios.js";

export default {
	// 获取请求验证码
	getNewRxcode(params={}){
		return request('GET','/common/get_new_rxcode');
	},

	// 获取请求验证码
	postFrontLog(params={}){
		return request('POST','/common/post_front_log', params);
	},

	// 获取门店列表
	getStoreList(params={}){
		return request('GET','/common/get_store_list', params);
	},
}